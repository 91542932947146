import React, { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import InputForm from "comp/FormComponents/inputForm";
import { Icon } from "@iconify/react";
import { XceltripButton } from "comp/Button";
import { dateFormatter } from "utils/helperFunctions";
import { getIcon } from "./config";
import dynamic from "next/dynamic";
import { MobileHotelSearch } from "./mobileHotelSearch";
import dayjs from "dayjs";

import useComponentVisible from "src/customHooks/useComponentVisible";
import useIsSmallDevice from "src/customHooks/useIsSmallDevice";
const DateSelection = dynamic(() => import("comp/FormComponents/datePicker"));
const HotelGuestSelectModal = dynamic(
  () =>
    import("./hotelGuestSelectModal").then((mod) => mod.HotelGuestSelectModal),
  { ssr: false }
);

interface Props {
  data: any;
  errors: any;
  isStart: any;
  hotelList: any;
  isHomePage: any;
  setisStart: any;
  disabled: boolean;
  isDetailsPage: any;
  handleSubmit: any;
  handleChange: any;
  isLoading: boolean;
  handleGuestData: any;
  handleDateChange: any;
  availableFilterType: any;
}

const SearchComponents = (props: Props) => {
  const router = useRouter();
  const {
    data,
    errors,
    disabled,
    hotelList,
    isLoading,
    isHomePage,
    handleChange,
    handleSubmit,
    isDetailsPage,
    handleGuestData,
    handleDateChange,
    availableFilterType,
  } = props;

  /* USED FOR THE MOBILE */
  const [showFilter, setshowFilter] = useState(false);

  const getData = (key: string) => {
    return data.guest.reduce(function (acc: any, obj: any) {
      return acc + obj[key];
    }, 0);
  };
  const [filteredChip, setFilteredChip] = useState([]);
  const [selectedChip, setSelectedChip] = useState<any>("All");

  const handleFilter = (key: String) => {
    setSelectedChip(key);
    if (key === "all") {
      setFilteredChip(hotelList);
    } else {
      setFilteredChip(hotelList.filter((x: any) => x.dataCat === key));
    }
  };

  const [alterCalendar, setAlterCalendar] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const {
    ref: hotelRef,
    isComponentVisible: showHotelList,
    setIsComponentVisible: setShowHotelList,
  } = useComponentVisible(false);
  const {
    ref: hotelListRef,
    isComponentVisible: scrollHotelList,
    setIsComponentVisible: setScrollHotelList,
  } = useComponentVisible(false, false);

  const isSmallDevice = useIsSmallDevice(() => setShowHotelList(false));

  useEffect(() => {
    if (hotelList.length === 0) {
      setSelectedChip("All");
      setFilteredChip([]);
      setScrollHotelList(false);
    }
    if (hotelList.length > 0) {
      setScrollHotelList(true);
    }
  }, [hotelList]);

  const [isMobileSearchModalShown, setisMobileSearchModalShown] =
    React.useState(false);
  const [isMobileGuestsModalShown, setisMobileGuestsModalShown] =
    React.useState(false);
  const commonConfig = {
    hotelInput: (
      <React.Fragment>
        <InputForm
          name="hotel"
          errors={errors}
          label="Location"
          autoComplete="off"
          showErrorLable={false}
          value={data.hotel || ""}
          handleChange={handleChange}
          placeholder={"Search Places, Hotels ...."}
          disabled={isDetailsPage}
          className={
            !isHomePage
              ? " "
              : " border-none pl-6  pt-7 focus:!ring-0 focus:!border-0 focus:!outline-none  !bg-transparent !font-semibold "
          }
          labelClass={
            !isHomePage ? " " : " absolute dark:text-dark-text-primary  "
          }
        />
        {isLoading && (
          <Icon
            icon="eos-icons:loading"
            width="30"
            height="30"
            className="absolute w-6 h-6 right-2 top-[32px] dark:text-dark-text-primary "
          />
        )}
      </React.Fragment>
    ),
    checkInDateSelection: (
      <DateSelection
        isRange={true}
        selectEnd={false}
        selectsOne={false}
        selectStart={true}
        data={data}
        name={"checkin"}
        errors={errors}
        label="Check-in"
        minDate={new Date()}
        endDate="checkout"
        alterOtherDate="checkout"
        placeholder="Select Date"
        handleDateChange={handleDateChange}
        setisStart={props.setisStart}
        // isStart={props.isStart}
        isStart={true}
        alterCalendar={false}
        setAlterCalendar={setAlterCalendar}
      />
    ),
    checkOutDateSelection: (
      <DateSelection
        isRange={true}
        isStart={false}
        selectEnd={true}
        selectsOne={true}
        data={data}
        name={"checkout"}
        errors={errors}
        startDate="checkin"
        endDate={"checkout"}
        label="Check-out"
        minDate={data.checkin}
        // alterOtherDate="checkin"
        placeholder="Select Date"
        handleDateChange={handleDateChange}
        setisStart={props.setisStart}
        alterCalendar={alterCalendar}
        setAlterCalendar={setAlterCalendar}
        positionRight={` ${
          router.pathname === "/" ? "lg:border-l justify-end" : " "
        }`}
        // isStart={!props.isStart}
      />
    ),
    guestSelect: (
      <HotelGuestSelectModal
        data={data}
        handleGuestData={handleGuestData}
        isComponentVisible={isComponentVisible}
        visibleref={ref}
        isMobileGuestsModalShown={isMobileGuestsModalShown}
        setisMobileGuestsModalShown={setisMobileGuestsModalShown}
      />
    ),
    searchButton: (
      <XceltripButton.IconButton
        isHomePage={isHomePage}
        text="Search"
        disabled={disabled}
        handleSubmit={(e) => {
          setshowFilter(!showFilter);
          handleSubmit(e);
        }}
        icon={<Icon className="w-5 h-5 " icon="bx:search" />}
      />
    ),
  };
  const commonShowingChip = availableFilterType.map(
    (eachChip: any, eachChipIndex: any) => (
      <span
        onClick={() => handleFilter(eachChip.label)}
        key={eachChipIndex + 1 + ""}
        className={`px-3 py-1 ${
          eachChip.label === selectedChip
            ? "bg-white dark:bg-dark-bg-secondary dark:text-dark-text-primary text-newText-100 border-2 border-newText-100"
            : "dark:border-dark-text-secondary"
        } rounded-full  gap-2  text-sm flex items-center w-max cursor-pointer border transition duration-300 ease`}
      >
        <Icon className="w-6 h-6" icon={getIcon(eachChip.label)} />
        <span className="text-light">{eachChip.label}</span>
      </span>
    )
  );
  const commonHoteList = (
    (filteredChip.length && filteredChip) ||
    hotelList
  ).map((eachList: any, eachIndex: any) => (
    <li
      className={`text-base  ${
        eachIndex == hotelList.length - 1 ? "mb-0" : "mb-4"
      }`}
      key={eachIndex + 1 + ""}
    >
      <span
        className="flex text-sm cursor-pointer"
        onClick={(e) => {
          handleChange({
            target: {
              name: "hotel_id",
              value: eachList.original._id ? eachList.value : "",
              original: eachList.original,
            },
          });
          setShowHotelList(false);
        }}
      >
        <Icon className="pr-2 w-7 h-7" icon={getIcon(eachList.dataCat)} />
        <div
          style={{ fontWeight: "bold !important" }}
          className="text location_text"
          dangerouslySetInnerHTML={{
            __html: eachList.text || eachList.value,
          }}
        />
      </span>
    </li>
  ));

  const commonComponents = (
    <>
      <div
        className={`flex wrapper flex-col lg:flex-row gap-3 bg-white ${
          isHomePage
            ? "dark:bg-dark-bg-primary shadow-[0_8px_32px_0_rgba(31,38,135,0.37)]"
            : " "
        } 
         px-4  py-4 rounded-b-lg lg:rounded-lg lg:rounded-tl-none `}
      >
        {/* <----------Name/Location-----------> */}
        <div
          ref={hotelRef}
          className={`rounded-[4px] px-3 py-2 pb-0 ${
            isHomePage
              ? "bg-[#f8f8f8] border border-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-transparent   search_placeholder lg:[&:has(input:focus,input:focus-within)]:border-newPrimary-100 dark:[&:has(input:focus,input:focus-within)]:border-dark-text-secondary"
              : ""
          } flex-1 wrapper-inputLocation relative col-span-2`}
        >
          <div
            onClick={() =>
              isSmallDevice
                ? !isDetailsPage && setisMobileSearchModalShown(true)
                : setShowHotelList(
                    showHotelList ? showHotelList : !showHotelList
                  )
            }
          >
            {commonConfig.hotelInput}
          </div>
          {isLoading && (
            <Icon
              icon="eos-icons:loading"
              width="30"
              height="30"
              className="absolute w-6 h-6 right-2 top-[32px]"
            />
          )}
          {hotelList.length > 0 && showHotelList && (
            <div
              ref={hotelListRef}
              className="showResult absolute shadow-m1 ml-[-28px] lg:mt-8 list-none bg-white p-4 pr-2 cursor-default rounded-xl before:absolute before:left-[20px] before:border-b-[8px] before:border-l-[8px] before:border-white before:dark:border-b-dark-bg-tertiary before:top-[-8px] before:border-r-[8px] before:border-r-transparent before:border-l-transparent z-[1] dark:text-dark-text-primary dark:bg-dark-bg-tertiary"
            >
              <div className="flex items-center gap-4 px-8 pl-0 mb-4 overflow-hidden overflow-x-auto chips">
                {commonShowingChip}
              </div>
              <ul
                className={`${
                  availableFilterType.length ? "" : ""
                } mb-2 overflow-hidden overflow-y-auto max-h-[40vmin] min-w-[30vmin] w-full h-full`}
              >
                {commonHoteList}
              </ul>
            </div>
          )}
        </div>

        {/* <----------calendar-----------> */}
        <div
          className={` ${
            !isHomePage
              ? " "
              : "  rounded-[4px] px-3 py-2  bg-[#f8f8f8] dark:bg-dark-bg-secondary dark:border-none dark:text-dark-text-primary border border-[#f8f8f8]  "
          } wrapper-inputDate relative flex justify-between gap-2 lg:grid lg:grid-cols-2 lg:col-span-2 lg:gap-6  `}
        >
          <div className="wrapper-inputDate-1 lg:min-w-[140px] relative lg:flex lg:flex-col  ">
            {commonConfig.checkInDateSelection}
          </div>
          <div className="wrapper-inputDate-1 lg:min-w-[140px] relative lg:flex lg:flex-col  ">
            {commonConfig.checkOutDateSelection}
          </div>
        </div>

        {/* <----------Guest-----------> */}
        <div
          className={` ${
            !isHomePage
              ? " "
              : "  rounded-[4px] px-3 py-2  bg-[#f8f8f8] border border-[#f8f8f8]   dark:bg-dark-bg-secondary dark:border-none dark:text-dark-text-primary  "
          } flex-1 col-span-2 wrapper-inputGuest `}
        >
          <label
            className="text-sm block pb-1.5   cursor-pointer"
            onClick={() => {
              setisMobileGuestsModalShown(true);
              setIsComponentVisible(!isComponentVisible);
            }}
          >
            Guests
          </label>
          <Icon
            icon={`solar:users-group-rounded-broken`}
            className={` ${
              router.pathname == "/"
                ? isComponentVisible
                  ? "lg:text-newPrimary-100"
                  : " text-gray-500"
                : "text-white"
            } mr-2 mb-[3px] `}
          />
          <div className="relative inline-block dropdown dropdown-hover">
            <div
              onClick={() => setisMobileGuestsModalShown(true)}
              className={` ${
                isComponentVisible ? "lg:text-newPrimary-100" : ""
              }`}
            >
              <label
                tabIndex={0}
                className="btn btn-ghost  !px-0 !h-0 !min-h-0 !border-none !bg-transparent text-base font-semibold !capitalize cursor-pointer "
                onClick={() => setIsComponentVisible(!isComponentVisible)}
              >
                {data.guest.length} Room
                {data.guest.length > 1 && "s"}, {getData("adult")} Adult
                {getData("adult") > 1 && "s"}
                {getData("children") !== 0 &&
                  `, ${getData("children")} Children`}
              </label>
            </div>
            {commonConfig.guestSelect}
          </div>
        </div>
        <div className="wrapper-Search lg:ml-auto ">
          {commonConfig.searchButton}
        </div>
      </div>
    </>
  );

  return (
    <React.Fragment>
      <MobileHotelSearch
        data={data}
        errors={errors}
        hotelList={hotelList}
        isLoading={isLoading}
        handleFilter={handleFilter}
        handleChange={handleChange}
        selectedChip={selectedChip}
        filteredChip={filteredChip}
        isShown={isMobileSearchModalShown}
        setisShown={setisMobileSearchModalShown}
        availableFilterType={availableFilterType}
      />

      {/* <--------------listing page, search section is defined here0------------------------> */}
      <div
        className={
          (!isHomePage &&
            " bg-base-300 stats shadow bg-gradient-to-r from-slate-900 to-slate-800 p-2 lg:pb-4 dark:bg-gradient-to-b dark:from-primary-800/40 dark:to-primary-800/40") ||
          " "
        }
      >
        <div className={(!isHomePage && "container mx-auto") || " "}>
          {!isHomePage && (
            // <---------mobile search------------->
            <div
              onClick={() => setshowFilter(!showFilter)}
              className="block py-4 text-white mobile-modify-search lg:hidden "
            >
              <div className="py-2 px-2  mb-2 sm:mb-2 bg-[hsla(0,0%,100%,.1)] rounded w-full relative">
                <div className="text-base capitalize">{data.hotel}</div>
                <div className="text-xs flex text-[#B589FF]">
                  <div>
                    <span>
                      {dayjs(new Date(data?.checkin)).format("DD MMM YYYY")}
                    </span>
                    <span className="px-1">-</span>
                    <span>
                      {dayjs(new Date(data?.checkout)).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <span>,</span>
                  <div className="pl-1">
                    <span>
                      {data.guest.length}{" "}
                      {data.guest.length > 1 ? "Rooms" : "Room"}
                    </span>{" "}
                    <span>{getData("adult")} Adults</span>
                  </div>
                </div>

                <div className="absolute top-1/2 right-4 translate-y-[-50%]">
                  <Icon
                    icon="ic:twotone-mode-edit-outline"
                    className="w-6 h-6"
                  />
                </div>
              </div>
            </div>
          )}
          {/* <-------desktop search in listing page----------> */}
          <div
            className={
              (!isHomePage &&
                `modify-search ${
                  (!showFilter &&
                    "  h-0 opacity-0 lg:visible lg:opacity-[1] lg:h-auto invisible") ||
                  "opacity-1"
                }`) ||
              " "
            }
          >
            {commonComponents}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchComponents;
